@import "./Global";

.run-colors-container {
    width: 100%;
    height: 600px;
    overflow: hidden;
}

.run-color-main-header {
    @include full-sive-main-header;
}

.run-swatches-container {
    display: flex;
    gap: 56px;
    width: fit-content;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.run-colors-tooltip-img {
    width: 152.79px;
    height: 200px;
}

.hover-flex-container-in-run-colors {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10%;
}

.hover-h1-run-colors {
    @include hover-order;
}

//❗️❗️❗️ responsive ❗️❗️❗️ 
@media(max-width:1000px) {
    .run-swatches-container {
        width:480px
    }
}

@media (max-width:900px) {
    .run-swatches-container {
        margin-top: -40px;
    }
}

@media (max-width:600px) {

    .run-swatches-container {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 300px;
        margin-top: -80px;
        gap: 32px;
        margin-left: 20px;
    }
}