@import "../Styles/_Global.scss";

.dash-values-flex {
    width: fit-content;
    display: flex;
    gap: 32px;
}

.dash-val-1 {
    font-family: 'Klee One', cursive;
    font-weight: 100;
    color: $-color-dark-grey;
    background-color: $color-div-gray;
    font-size: 25px;
    width: 200px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
}

.dash-val-2 {
    @extend .dash-val-1;
}

.dash-val-3 {
    @extend .dash-val-1;
}

// ❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1400px) {
    .dash-val-1 {
        width: 180px;
        font-size: 21px;
        height: 54px;
    }
}


@media (max-width: 1200px) {
    .dash-val-1 {
        width: 150px;
        font-size: 16px;
        height: 40px;
    }
}

@media (max-width: 900px) {
    .dash-values-flex  {
        flex-wrap: wrap;
    }
}

@media (max-width: 600px) {
    .dash-values-flex  {
        gap: 16px;
    }
}

@media (max-width: 500px) {
    .dash-values-flex  {
        flex-direction: column;
    }

    .dash-val-1 {
        width: 80vw
    }
}


