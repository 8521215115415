@import "./Global";

.run-ui-main-container {
    width: 100%;
    height: 900px;
    overflow: hidden;
    background-color: $color-div-gray;
}

.run-ui-main-header {
    @include full-sive-main-header;
}

// .run-ui-img-flex {
//     display: flex;
//     width: fit-content;
//     position: relative;
//     top:7%;
//     left: 50%;
//     transform: translateX(-50%);
// }

.run-ui-img-flex {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    grid-template-rows: 100%;
    margin-left: 100px;
    margin-right: 100px;
}

.run-ui-img-item {
    width:350px;
    transition: transform .3 ease-in-out;
    align-self: center;
    justify-self: center;
}
// ❗️❗️❗️ responsive ❗️❗️❗️ 
@media (max-width: 1600px) {
    .run-ui-img-item {
        width:300px;
    }
    .run-ui-main-container {
        height: 800px;
    }
}

@media (max-width: 1400px) {
    .run-ui-img-item {
        width:250px;
    }
    .run-ui-main-container {
        height: 700px;
    }
}

@media (max-width: 1000px) {
    .run-ui-img-flex {
        display: flex; 
        flex-wrap: wrap;
        width: 100vw;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .run-ui-img-item {
        margin-left: 30px;
    }
    .run-ui-main-container {
        height: 1020px;
    }
}


@media (max-width: 560px) {
    .run-ui-img-item {
        width: 150px;
    }
    .run-ui-main-container {
        height: 700px;
    }
    .run-ui-img-flex {
        margin-top: -50px;
    }
}

@media (max-width: 540px) {
    .run-ui-main-container {
        height: 620px;
    }
}

