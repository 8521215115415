@import "../styles/Global.scss";

.note-section {
    width: 100%;
    height: 900px;
    background-color: $color-div-gray;
    overflow: hidden;
}

.notes-header {
    @include full-sive-main-header;
}

.hover-notes-order {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: relative;
    top: 100px;
    & h1 {
        @include hover-order;
    }
}

.notes-flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.note-close {
    transition: transform .2s, filter .2s;
    width: 200px;
    height: 200px; 
}
.select {
    transform: scale(1.2);
}

.blur {
    filter: blur(16px);
    opacity: .6;
}

.note-open {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: opacity .3s ;
}

.select ~ .open-note-1  {
    opacity: 1;
    visibility: visible;
    left: 240px;
    top: 100px;
}
.select ~ .open-note-2  {
    opacity: 1;
    visibility: visible;
    left: 480px;
    top: 80px;
}

.select ~ .open-note-3  {
    opacity: 1;
    visibility: visible;
    left: 620px;
    top: 220px;
    transform: translateX(-50%);
}

.select ~ .open-note-4  {
    opacity: 1;
    visibility: visible;
    right: 500px;
    top: 70px;
}

.select ~ .open-note-5  {
    opacity: 1;
    visibility: visible;
    right: 230px;
    top: -20px;
}

.select ~ .open-note-6  {
    opacity: 1;
    visibility: visible;
    left: 360px;
    top: 10px;
}

.select ~ .open-note-7  {
    opacity: 1;
    visibility: visible;
    left: 210px;
    top: 30px;
}

.select ~ .open-note-8  {
    opacity: 1;
    visibility: visible;
    right: 600px;
    top: -40px;
}

.select ~ .open-note-9  {
    opacity: 1;
    visibility: visible;
    right: 350px;
    top: -30px;
}

//❗️❗️❗️ responsive ❗️❗️❗️
@media(max-width: 1400px) {
.notes-flex-container {
    gap: 0px;
    width: 1000px;

    .note-close {
        scale: .9;
    }

    .note-open {
        scale: .8;
    }

    .select ~ .open-note-1 {
        left: 180px;
    }

    .select ~ .open-note-2 {
        left: 340px;
    }

    .select ~ .open-note-3 {
        left: 450px;
        top: 165px;
    }

    .select ~ .open-note-4 {
        left: -40px;
        right: 0px;
    }

    .select ~ .open-note-5 {
        right: 120px;
    }

    .select ~ .open-note-6 {
        left: 250px;
    }

    .select ~ .open-note-7 {
        left: 140px;
    }

    .select ~ .open-note-8  {
        right: 420px;
    }

    .select ~ .open-note-9  {
        right: 250px;
    }
}
}

@media(max-width: 1200px) {
        .note-section {
            height: 600px;
        }
        .notes-flex-container {
            gap: -204px;
            width: 800px;
            top: 35%;
        
        }
        .note-close {
            width: 150px;
            height: 150px;
        }

    .hover-notes-order {
        display: none;
    }

    .select ~ .open-note-1 {
        display: none;
    }

    .select ~ .open-note-2 {
        display: none;
    }

    .select ~ .open-note-3 {
        display: none;
    }

    .select ~ .open-note-4 {
        display: none;
    }

    .select ~ .open-note-5 {
        display: none;
    }

    .select ~ .open-note-6 {
        display: none;
    }

    .select ~ .open-note-7 {
        display: none;
    }

    .select ~ .open-note-8  {
        display: none;
    }

    .select ~ .open-note-9  {
        display: none;
    }
}

@media (max-width: 1000px) {
    .notes-header {
        scale: .9;
    }
}

@media (max-width: 800px) {
    .notes-header {
        scale: .8;
    }

    .note-section {
        height: 500px;
    }
}

@media (max-width: 700px) {
    .note-section {
        height: 700px;
    }

    .notes-header {
        scale: .7;
    }

    .notes-flex-container {
        width: 500px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    } 

    .close-note-4 {
        grid-column-start: 3;
    }

    .close-note-5 {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    .close-note-6 {
        grid-column-start: 1;
        grid-row-start: 2;
    }
}


@media (max-width: 500px) {
    .note-section {
        height: 600px;
    }

    .notes-flex-container {
        width: 400px;
        height: 400px;
        justify-items: center;
        align-items: center;
    } 
}

@media (max-width: 400px) {
    .note-section {
        height: 420px;
    }

    .notes-flex-container {
        width: 300px;
        height: 300px;
        top: 120px;
    } 

    .note-close {
        width: 100px;
        height: 100px;
    }
}



