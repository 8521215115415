@import "./_Global.scss";

.hero-typo-container {
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
    width: max-content;

    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;


    &-h1 {
        font-family:  'Quicksand', sans-serif;
        font-weight: 100;
        font-size: 54px;
        line-height: 100%;
        letter-spacing: 0.04em;
        color: #D9EBFF;

    }

    &-h2 {
        font-family:  'Quicksand', sans-serif;
        font-weight: 100; 
        font-size: 120px;
        line-height: 100%;
        letter-spacing: 0.08em;
        color: #80BCFF;

        & span {
            font-weight: 100;
            opacity: .3;
        }
    }
}



// ❗️❗️❗️ responsive ❗️❗️❗️

@media (max-width: 1600px) {

    .hero-typo-container h1 {
        font-size: 40px;
    }

    .hero-typo-container h2{
        font-size: 100px;
    }
}

@media (max-width: 1000px) {
    .hero-typo-container h1 {
        font-size: 32px;
    }

    .hero-typo-container h2{
        font-size: 80px;
    }
}

@media (max-width: 800px) {

      .hero-typo-container h2{
        font-size: 72px;
    } 
}

@media (max-width: 650px) {

    .hero-typo-container {
        gap: 24px;
    }

    .hero-typo-container h2{
        font-size: 64px;
    } 
}

@media (max-width: 575px) {
    .hero-typo-container h1 {
        font-size: 24px;
    }

    .hero-typo-container h2{
        font-size: 54px;
    }
}

@media (max-width: 488px) {
    .hero-typo-container {
        width: 200px;
    }

    .hero-typo-container h2{
        text-align: center;
        line-height: 130%;
        font-size: 64px;
    }
}


    
