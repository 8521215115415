@import "../Styles/Global";

.footer-container {
    width: 100%;
    height: 300px;
    background-color: #191b1d;
    overflow: hidden;


    & h1 {
        color: white;
        font-family: 'Klee One', cursive;
        text-transform: uppercase;
        letter-spacing: 6px;
        font-size: 56px;
        text-align: center;
        margin-top: 24px;

    }

    & p {
        font-family: 'Quicksand', sans-serif;
        color: white;
        text-transform: uppercase;
        letter-spacing: 6px;
        opacity: .5;
        text-align: center;
        margin-top: 56px;
    }
}

.contact-fotter-flex {
    display: flex;
    gap: 120px;
    justify-content: center;
    align-items: center;
    margin-top: 56px;
    overflow: hidden ;
}

.mail-container {
    display: flex;
    justify-items: center;
    align-items: center;
    width: fit-content;
    font-family: 'Quicksand', sans-serif;
    gap: 8px;

    & h2 {
        color: white;
        font-weight: 300;
        letter-spacing: 1px;
    }

    & img {
        scale: .7;
    }
}

.phone-container {
    @extend .mail-container;

    & img {
        scale: .8;
    }
}


// ❗️❗️❗️ responsive ❗️❗️❗️ 
@media (max-width: 800px) {
    .footer-container {
        & h1 {
            font-size: 48px;
        }

        & p {
            font-size: 12px;
        }

    }
    

    .mail-container h2 {
        font-size: 18px;
    }

    .phone-container h2 {
        font-size: 18px;
    }

}

@media (max-width: 700px) {
    .footer-container {
        & h1 {
            font-size: 40px;
        }
    }
}

@media (max-width: 600px) {
    .footer-container {
        & h1 {
            font-size: 32px;
        }
    }

    .footer-container {
        height: 250px;
    }
}

@media (max-width: 570px) {
    .contact-fotter-flex {
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;
    }

    .footer-container p {
        margin-top: 30px;
        letter-spacing: 4px;

    }
}

@media (max-width: 500px) {
    .footer-container p {
        display: none;
    }
}

