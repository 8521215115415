@import "./Global";

.run-photo-container {
    width: 100%;
    height: 800px;
    overflow: hidden;
}

.run-img-main-haeder {
    @include full-sive-main-header;
}

.run-photos-flex {
    display: flex;
    gap: 32px;
    width: fit-content;
}

.run-img-item {
    width: 284px;
    box-shadow: $shadow-gentle;
    border-radius: 24px;;
}

.run-img-p {
    @include paragraph-text;
    width: 1200px;
    text-align: center;

    & b {
        @include bolt-in-text;
    }
}

.run-img-and-p-container {
    display: flex;
    flex-direction: column;
    gap: 56px;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

//❗️❗️❗️ resonsive ❗️❗️❗️
@media(max-width:1440px) {
    .run-img-item {
        width: 200px;
    }
    .run-img-p {
        width: 800px;
    }
    .run-photo-container {
        height: 700px;
    }
}
@media(max-width:1000px) {
    .run-img-item {
        width: 170px;
        border-radius: 16px;
    }
    .run-img-p {
        width: 700px;
    }
}

@media (max-width:800px) {
    .run-img-p {
        font-size: 18px;
        width: 500px;
    }
    .run-photos-flex {
        flex-wrap: wrap;
        width: 500px;
        justify-content: center;
        align-items: center;
    }
    .run-img-and-p-container {
        margin-top: 24px;
        gap:32px;
    }
    .run-photo-container {
        height: 800px;
    }
    .run-img-main-haeder {
        scale: .9;
    }
}
@media (max-width:600px) {
    .run-img-and-p-container {
        margin-top: -20px;
    }
    .run-img-p {
        width: 420px;
    }
    .run-photo-container {
        height: 750px;
    }
}
@media(max-width:470px) {
    .run-img-p {
        font-size: 16px;
        width: 290px;
    }
    .run-img-item  {
        width:100px;
        border-radius: 8px;
    }
    .run-photos-flex {
        width: 300px;
    }
    .run-photo-container {
        height: 650px;
    }
    .run-img-main-haeder {
        scale: .8;
    }

}