@import "./_Global.scss";

.home-scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap: 12px;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    text-align: center;
    overflow: hidden;


    &-h1 {
        font-family:  'Quicksand', sans-serif;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 16px;
        line-height: 100%;
        color: rgba(217, 235, 255, 0.7);
        letter-spacing: 0.6em;
    }


}

// ❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1600px) {

    .home-scroll img {
        scale: .8;
    }
}

@media (max-width: 900px) {

    .home-scroll-h1 {
        font-size: 14px;
    }
}

@media (max-width: 488px) {
    .home-scroll {
        width: fit-content;
    }

    .home-scroll-h1 {
        display: none;
    }
}

