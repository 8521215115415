@import "./Global";

.aime-comp-container {
    width: 100%; 
    height: 700px;
    background-color: $color-div-gray;
    overflow: hidden;
}

.aime-comp-main-header {
    @include full-sive-main-header;
}

.aime-comp-flex {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    & img {
        width: 600px;
    }
}

.aime-cont-comp-text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 80px;

    & h1 {
        @include section-hedaer;
    }

    & p {
        @include paragraph-text;
        width: 600px;
    }
}

//❗️❗️❗️responsive❗️❗️❗️
@media (max-width: 1450px) {
    .aime-comp-flex {
        margin-right: 80px;
        margin-left: 120px;
    }
    .aime-cont-comp-text {
        & h1 {
            font-size: 48px;
        }
    }
}

@media (max-width: 1200px) {
    .aime-comp-container {
        height:600px; 
    }
    .aime-comp-flex {
        margin-right: 120px;
        margin-left: 140px;
        margin-top: -24px;
        & img {
            width: 500px;
        }
    }
    .aime-cont-comp-text {
        & p {
            width: 450px;
            font-size: 18px;
        }
        & h1 {
            font-size: 40px;
        }
    }
}

@media (max-width: 1000px) {
    .aime-comp-container {
        height:550px; 
    }
    .aime-comp-flex {
        margin-right: 140px;
        margin-left: 160px;
        margin-top: -32px;
        & img {
            width: 450px;
        }
    }
    .aime-cont-comp-text {
        & p {
            width: 400px;
            font-size: 18px;
        }
        & h1 {
            font-size: 32px;
        }
    }
}

@media (max-width: 900px) {
    .aime-comp-container {
        height: 800px;
    }
    .aime-comp-flex {
        flex-direction:column-reverse;
        align-items: center;
        margin-left: 50%;
        transform: translate(-50%);
    }
    .aime-cont-comp-text {
        margin-top: 12px;
        text-align: center;
        align-items: center;
    }
}

@media (max-width: 520px) {
    .aime-comp-flex {
        & img {
            width: 400px;
        }
    }
    .aime-comp-main-header {
        scale: .9;
    }
    .aime-cont-comp-text {
        margin-top: 12px;
        & p {
            font-size: 16px;
            width: 300px;
        }
        & h1 {
            font-size: 26px;
            margin-bottom: -2px;
        }
    }
    .aime-comp-container {
        height: 700px;
    }
}

@media (max-width: 400px) {
    .aime-comp-container {
        height: 550px;
    }
    .aime-comp-flex {
        & img {
            width: 300px;
        }
    }
    .aime-cont-comp-text {
        margin-top: 0px;
        & p {
            font-size: 12px;
            width: 260px;
        }
        & h1 {
            font-size: 22px;
            margin-bottom: -2px;
        }
    }
    .aime-comp-main-header {
        scale: .8;
    }
}


