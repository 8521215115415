@import "../Styles/Global.scss";

.aime-charts-sections-main-div {
    width: 100%; 
    height: 1450px;
    overflow: hidden;
}

.charts-main-header {
    @include full-sive-main-header;
}

.aime-chart-description {
    font-family: 'Klee One', cursive;
    font-weight: 100;
    font-size: 27px;
    line-height: 100%;
    color: $-color-dark-grey;
    text-align: center;

    margin-top: 32px;
    margin-bottom: 64px;

    & b {
        font-size: 40px;
    }
}

.all-charts-container {
    display: flex;
    flex-direction: column;
    gap: 120px;
    justify-content: center;
    align-items: center;
}

.aime-charts-top-flex-with-q {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.aime-charts-bottom-flex-with-q {
    @extend .aime-charts-top-flex-with-q;
}

.q-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 90px;;
    background-color: $color-div-gray;
    border-radius: 1000000px;
    gap: 8px;
    
    & h1 {
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        font-size: 27px;
        color: $-color-dark-grey;
    }

    & h2 {
        font-family: 'Quicksand', sans-serif;
        font-weight: 300;
        font-size: 18px;
        color: $color-light-grey;
    }
}

.aime-charts-top-container {
    display: flex;
    justify-items: center;
    align-items: center;
    width: fit-content;
    height: 340px;
    gap: 96px;
}

.aime-charts-bottom-container {
    @extend .aime-charts-top-container ;
}


.circle-rotate {
    width: fit-content;
    height: fit-content;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.chart-item-text {
    rotate:(90deg);
    font-family: 'Quicksand', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    position: absolute;
    transform: translate(-50%);
    top: 46%;
    left: 4%;
    width: 264px;
    height: 264px;
}
.chart-main-p {
    font-weight: 500;
    font-size: 40px;
    color: $-color-dark-grey;
    letter-spacing: 3px;
    text-align: center;
}

.chart-sub-p {
    font-size: 21px;
    color: $color-light-grey;
    letter-spacing: 2px;
}

.chart-answer {
    position:absolute;
    top: 280px;
    width: 300px;
    text-align: center;
    @include paragraph-text;
}

.chart {
    width: 300px; 
    height: 300px;

}

.chart-circle {
    fill: transparent; 
    stroke-width: 12px;
    stroke-linecap: round;
    scale: 2;
    transition-delay: .5s;

    stroke-dasharray: 377;
    stroke-dashoffset: -377;
}

.bg-circle {
    fill: transparent; 
    stroke-width: 12px;
    scale: 2;
    stroke: $color-div-gray;
}

.animation1 {
    stroke-dashoffset: -211;
    transition: all 1s ease-in-out;
}

.animation2 {
    stroke-dashoffset: -198;
    transition: all 1s ease-in-out;
}

.animation3 {
    stroke-dashoffset: -170;
    transition: all 1s ease-in-out;
}

.animation4 {
    stroke-dashoffset: -206;
    transition: all 1s ease-in-out;
}

.animation5 {
    stroke-dashoffset: -181;
    transition: all 1s ease-in-out;
}

.animation6 {
    stroke-dashoffset: -210;
    transition: all 1s ease-in-out;
}

//❗️❗️❗️ responsive ❗️❗️❗️ 
@media (max-width: 1400px) {
    .charts-main-header {
        transform: scale(.75);
    }
    .aime-charts-sections-main-div {
        height: 1320px;
    }
    .aime-charts-top-container {
        scale: .8;
    }
    .q-wrap {
        scale: .8;
    }
    .aime-charts-bottom-flex-with-q {
        margin-top: -48px;
    }
}

@media (max-width: 1200px) {
    .charts-main-header {
        transform: scale(.65);
    }
}

@media (max-width: 1000px) {
    .charts-main-header {
        transform: scale(.55);
    }
    .aime-charts-sections-main-div {
        height: 1050px;
    }
    .aime-chart-description {
        line-height: 150%;
        margin-left: 180px;
        margin-right: 180px;
        margin-bottom: 32px;
        
    }
    .aime-charts-top-container {
        scale: .6;
    }
    .q-wrap {
        scale: .6;
    }
    .aime-charts-top-container {
        margin-top: -72px;
    }
    .aime-charts-bottom-flex-with-q {
        margin-top: -120px;
    }
}

@media (max-width:820px) {
    .charts-main-header {
        transform: scale(.45);
    }
     
    .aime-chart-description {
        font-size: 18px;

        b {
            font-size: 32px;
        }
    }
}

@media (max-width: 680px) {
    .aime-charts-sections-main-div {
        height: 930px;
    }
    .charts-main-header {
        transform: scale(.35);
    }
     
    .aime-chart-description {
        margin-top: -16px;
        margin-left: 120px;
        margin-right: 120px;
        margin-bottom: 12px;
    }
    .aime-charts-top-container {
        scale: .5;
        margin-bottom: -16px;
    }
    .q-wrap {
        scale: .5;
    }
}

@media (max-width: 560px) {
    .aime-charts-sections-main-div {
        height: 840px;
    }
    .charts-main-header {
        transform: scale(.3);
    }
     
    .aime-chart-description {
        margin-left: 100px;
        margin-right: 100px;
    }
    .aime-charts-top-container {
        scale: .4;
        margin-bottom: -50px;
    }
    .aime-chart-description {
        font-size: 16px;

        b {
            font-size: 24px;
        }
    }
}


@media (max-width: 470px) {
    .aime-charts-sections-main-div {
        height: 700px;
    }
    .charts-main-header {
        transform: scale(.2);
    }
     
    .aime-chart-description {
        margin-left: 100px;
        margin-right: 100px;
        margin-top: -34px;
    }
    .aime-charts-top-container {
        scale: .3;
        margin-bottom: -80px;
    }
    .q-wrap {
        margin-bottom: -40px;
    }
    .aime-chart-description {
        font-size: 12px;

        b {
            font-size: 16px;
        }
    }
}