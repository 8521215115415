@import "./Global.scss"; 

.aime-personas-main-container {
    width: 100%; 
    height: 900px;
    overflow: hidden;
    background-color: $color-div-gray;
}

.aime-personas-header {
    @include full-sive-main-header;
}

.hover-flex-container-in-personas {
    display: flex; 
    gap: 16px;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 60px;
    margin-bottom: 24px;

    & h1 {
        @include hover-order;
    }
}

.personas-flex {
    position: relative;
    top: 56px;
    display: flex;
    justify-content: center;
    gap: 72px;

    & img {
        width: 600px;
        transition: all .2s;
    }
}

.persona1-scale {
    scale: 1.5;
    margin-right: 100px;
}

.persona2-scale {
    scale: 1.5;
    margin-left: 100px;
}

// ❗️❗️❗️ responsive ❗️❗️❗️ 
@media (max-width: 1700px) {
    .aime-personas-main-container {
        height: 800px;
    }
    .personas-flex {
        & img {
            width: 500px;
        }
    }
}

@media (max-width: 1500px) {
    .persona1-scale {
        scale: 1.2;
        margin-right: 40px;
    }
    
    .persona2-scale {
        scale: 1.2;
        margin-left: 40px;
    }
}

@media (max-width: 1250px) {
    .aime-personas-main-container {
        height: 700px;
    }
    .personas-flex {
        & img {
            width: 400px;
        }
    }
}

@media (max-width:1000px) {
    .hover-flex-container-in-personas {
        display: none;
    }

    .personas-flex {
        top: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 38px;
    }
    .persona1-scale {
        scale: 1.4;
        margin: 24px;
    }
    
    .persona2-scale {
        scale: 1.4;
        margin: 24px;
    }
    .aime-personas-main-container {
        height: 900px;
    }
}

@media (max-width: 600px) {
    .persona1-scale {
        scale: 1;
        margin: 0;
    }
    
    .persona2-scale {
        scale: 1;
        margin: 0;
    }
    .aime-personas-main-container {
        height: 840px;
    }
}

@media (max-width: 450px) {
    .personas-flex {
        margin-top: -24px;
        & img {
            width: 300px;
        }
    }
    .aime-personas-main-container {
        height: 640px;
    }
}