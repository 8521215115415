@import "./Global"; 

.aime-solution-main-container {
    width: 100%; 
    height: 850px; 
    overflow: hidden;
}

.aime-solution-header {
    @include full-sive-main-header;
}

.aime-solution-grid {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 60% 40%;
    
    & img {
        width: 700px;
        align-self: center;
        justify-self: center;
    }
}

.aime-solution-p-flex {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 800px;
    align-self: center;
    justify-self: center;
     
    & p {
        @include  paragraph-text;

        & b {
            @include  bolt-in-text;
        }
    }
}

//❗️❗️❗️responsive❗️❗️❗️
@media (max-width: 1600px) {
    .aime-solution-grid {
        margin-right: 100px;
        margin-left: 80px;
        margin-top: -40px;
        & img {
            scale: .9;
        }
    }
    .aime-solution-p-flex {
     width: 680px;
    }
    .aime-solution-main-container {
        height: 800px;
    }
}

@media (max-width: 1400px) {
    .aime-solution-main-container {
        height: 650px;
    }
    .aime-solution-grid {
        margin-top: -120px;
        & img {
            scale: .8;
        }
    }
    .aime-solution-p-flex {
        width: 600px;
        & p {
            font-size: 18px;
        }
       }
}

@media (max-width: 1200px) {
    .aime-solution-grid {
        margin-right: 80px;
        margin-left: 60px;
    }
    .aime-solution-p-flex {
        width: 550px;
        & p {
            font-size: 16px;
        }
    }
    .aime-solution-grid {
        & img {
            scale: .7;
        }
    }
}

@media (max-width: 900px) {
    .aime-solution-main-container {
        height: 1000px;
    }
    .aime-solution-grid {
        grid-template-rows: 60% 40%;
        grid-template-columns: 100%;
        margin-top:-80px
    }

    .aime-solution-grid {
        & img {
            grid-row-start: 1;
        }
    }

    .aime-solution-p-flex {
        margin-top: -80px;
        justify-content: center;
        align-items: center;
        & p {
            text-align: center;
        }
    }
}

@media (max-width: 650px) {
    .aime-solution-p-flex {
        width: 500px;
    }
}

@media (max-width: 500px) {
    .aime-solution-p-flex {
        width: 400px;
        margin-top: -120px;
    }
    .aime-solution-grid {
        margin-top: -150px;
        & img {
           scale: .6;
        }
    }
}

@media (max-width: 450px) {
    .aime-solution-p-flex {
        width: 300px;
        margin-top: -150px;
        & p {
            font-size: 12px;
        }
    }
    .aime-solution-grid {
        margin-top: -180px;
        & img {
           scale: .5;
           margin-left: 20px;
        }
    }
    .aime-solution-main-container {
        height: 850px;
    }
}

@media (max-width: 391px) {
    .aime-solution-grid {
        margin-top: -200px;
        & img {
           scale: .4;
           margin-left: 20px;
        }
    }
    .aime-solution-p-flex {
        margin-top: -190px;
    }
    .aime-solution-main-container {
        height: 800px;
    }
}

