@import "_Global.scss";

.dash-colors-container {
    width: 100%;
    height: 880px;
    overflow: hidden;
}

.dash-colors-header {
    @include full-sive-main-header;
}

.dash-colors-group {
    width: fit-content;
    display: flex;
    flex-direction: column;
}

.dash-colors-group-flex {
    display: flex;
    gap: 48px;
    width: fit-content;
    
}

.dash-color-top {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 96px;
    margin-bottom: 96px;
}

.dash-color-bottom {
    @extend .dash-color-top;
}

.dash-align-colors {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 56px;
}


.dash-color-gropu-h1 {
    margin-bottom: 40px;
    text-align: center;
    @include section-hedaer;
    font-size: 43px;
}

.hover-flex-container-in-dash-colors {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: -24px;
    margin-top:64px;
    font-family: 'Quicksand', sans-serif;
}

.hover-h1 {
    @include hover-order;
}

//❗️❗️❗️responsive❗️❗️❗️
@media (max-width: 1500px) {
    .dash-align-colors {
        scale: .9;
    }
}
@media (max-width: 1400px) {
    .dash-colors-container {
        height: 800px;
    }
    .dash-align-colors {
        scale: .8;
        margin-top: -12px;
    }
    .hover-h1 {
        margin-top:-12px;
    }
    .hover-flex-container-in-dash-colors {
        & img {
            margin-top: -10px;
        }
    }
}
@media (max-width: 1200px) {
    .dash-colors-container {
        height: 750px;
    }
    .dash-align-colors {
        scale: .7;
        margin-top: -24px;
    }
    .hover-h1 {
        margin-top:-24px;
    }
    .hover-flex-container-in-dash-colors {
        & img {
            margin-top: -24px;
        }
    }
}

@media (max-width: 1000px) {
    .dash-color-gropu-h1 {
        font-size: 32px;
        text-align:right;
        margin-left: -32px;
    }
   .dash-align-colors {
    margin-left: 24px;
    margin-top: -48px;
   }
   .dash-colors-container {
    height: 700px;
}
}

@media (max-width: 900px) {
    .hover-flex-container-in-dash-colors {
        display: none;
    }
    .dash-colors-container {
        height: 600px;
    }
}

@media (max-width: 770px) {
    .dash-color-bottom {
        flex-wrap: wrap;
        justify-content: center;
    }
    .dash-align-colors {
        margin-top: -100px;
    }
    .dash-colors-container {
        height: 750px;
    }
}

@media (max-width: 600px) {
    .dash-align-colors {
        margin-top: -120px;
    }
    .dash-colors-group-flex {
        gap: 24px;
    }
    .dash-color-gropu-h1 {
        margin-bottom: 20px;
    }
    .dash-colors-container {
        height: 650px;
    }
    .dash-color-top {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 490px) {
    .dash-align-colors {
        margin-top: -160px;
    }
    .dash-colors-group {
        margin-bottom: -40px;
    }
    .dash-colors-container {
        height: 860px;
    }
}
