@import "./Global";

.aime-flow-container {
    width: 100%; 
    height: 1200px;
    overflow: hidden;
    background-color: $color-div-gray;
}

.aime-flow-header {
    @include full-sive-main-header;
}

.aime-flow {
    width: fit-content;
    position: relative;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);

    & img {
        width: 1200px;
    }
}

//❗️❗️❗️responsive❗️❗️❗️
@media (max-width: 1400px) {
    .aime-flow-container {
        height: 1020px;
    }
    .aime-flow {
        & img {
            width: 1000px;
        }
    }
}

@media (max-width: 1100px) {
    .aime-flow-container {
        height: 900px;
    }
    .aime-flow {
        & img {
            width: 800px;
        }
    }
}

@media (max-width: 880px) {
    .aime-flow-container {
        height: 700px;
    }
    .aime-flow {
        & img {
            width: 600px;
        }
    }
}

@media (max-width: 670px) {
    .aime-flow-container {
        height: 600px;
    }
    .aime-flow {
        & img {
            width: 500px;
        }
    }
}

@media (max-width: 500px) {
    .aime-flow-container {
        height: 450px;
    }
    .aime-flow {
        margin-top: -24px;
        & img {
            width: 350px;
        }
    }
}

@media (max-width: 400px) {
    .aime-flow-container {
        height: 400px;
    }
    .aime-flow {
        margin-top: -24px;
        & img {
            width: 300px;
        }
    }
}






