
// font quik sand & klee one from google fonts
@import url('https://fonts.googleapis.com/css2?family=Klee+One:wght@400;600&family=Quicksand:wght@300;400;500;600;700&display=swap');

$-color-dark-grey: #37474F;
$color-light-grey: #607D8B;
$color-primary-blue: #007AFF; 
$color-div-gray: #F5F6FA;

$shadow-gentle: 0px 50px 100px rgba(75, 85, 99, 0.2);
$shadow-wireframe: 8px 16px 49px -16px rgba(45, 42, 62, 0.25);
$shadow-btn: 0px 16px 40px -12px rgba(0, 122, 255, 0.7);
$shadow-note: 0px 20px 24px -8px rgba(88, 94, 117, 0.07);

@mixin css-reset {
    margin: 0; 
    box-sizing: border-box;
}

@mixin full-size-page-margin {
    margin-left: 200px;
    margin-right: 200px;
}

@mixin full-sive-main-header {
    display: block;
    margin-top: 32px;
    margin-left: 0;

    @media (max-width: 1800px) {
        transform: scale(0.9);
        transform-origin: left;
        margin-left: 0;
    }

    @media (max-width: 1600px) {
        transform: scale(0.8);
        transform-origin: left;
        margin-left: 0;
    }

    @media (max-width: 1400px) {
        transform: scale(0.75);
        transform-origin: left;
        margin-left: 0;
        margin-top: 24px;
    }

    @media (max-width: 1200px) {
        transform: scale(0.7);
        transform-origin: left;
        margin-left: 0;
    }

    @media (max-width: 1000px) {
        transform: scale(0.65);
        transform-origin: left;
        margin-left: 0;
        margin-top: 8px;

    }

    @media (max-width: 800px) {
        transform: scale(0.6);
        transform-origin: left;
        margin-left: 0;
    }

    @media (max-width: 600px) {
        transform: scale(0.4);
        transform-origin: left;
        margin-left: 0;
        margin-top: -16px;

    }

    @media (max-width: 400px) {
        transform: scale(0.35);
        transform-origin: left;
        margin-left: 0;
    }


    @media (max-width: 320px) {
        transform: scale(0.3);
        transform-origin: left;
        margin-left: 0;
        margin-top: -24px;

    }
}

@mixin section-hedaer{
    font-family: 'Klee One', cursive;
    font-weight: 100;
    font-size: 56px;
    line-height: 100%;
    color: $-color-dark-grey;
    width: fit-content;

}

@mixin paragraph-text {
    font-family: 'Quicksand', sans-serif;
        font-weight: 300;
        font-size: 21px;
        line-height: 150%;
        color: $color-light-grey;
}

@mixin bolt-in-text {
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}

@mixin flex-container {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

@mixin primary-btn {
    width: 260px;
    height: 64px;
    border: none;
    border-radius: 12px;
    background-color: $color-primary-blue;
    color: white;

    font-family:  'Quicksand', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 4px;
    text-transform: uppercase;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    box-shadow: $shadow-btn;
    transition: width .2s ease-in-out;
    
    cursor: pointer;

    &:hover {
        width: 300px;
    }

    &:focus {
        box-shadow: none;
        width: 260px;
    }

    @media (max-width: 1320px) {
        width: 240px;
        height: 56px;
    }

    @media (max-width: 930px) {
        width: 240px;
        height: 48px;
    }
}

@mixin hover-order {
    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    font-size: 21px;
    line-height: 100%;

    letter-spacing: 0.24em;
    text-transform: uppercase;

    color: #C7CCD3;

}

