@mixin position {
    position: absolute;
    z-index: 1;
}

span {
    overflow: hidden;
}


.symbols-short-css {
    @include position();
        top: 15%; 
        left: 50%;
        
    }

    .symbols-short-pen {
        @include position();
        top: 80%; 
        left: 70%;
    }

    .symbols-short-react {
        @include position();
        top: 10%; 
        left: 86%;
    }

    .symbols-short-js {
        @include position();
        top: 55%; 
        left: 10%;
    }

    .symbols-medium-typo {
        @include position();
        top: 40%; 
        left: 80%;
    }

    .symbols-medium-eye {
        @include position();
        top: 20%; 
        left: 20%;
    }

    .symbols-medium-vert {
        @include position();
        top: 80%; 
        left: 90%;
    }

    .symbols-medium-git {
        @include position();
        top: 75%; 
        left: 30%;
    }

    .symbols-long-typo {
        @include position();
        top: 75%; 
        left: 50%;
    }

    .symbols-long-vert {
        @include position();
        top: 25%; 
        left: 70%;
    }

    .symbols-long-js {
        @include position();
        top: 10%; 
        left: 10%;
    }

    .symbols-long-html {
        @include position();
        top: 80%; 
        left: 10%;
    }


    
// ❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1400px) {

    .parallax-container img {
        scale: .9;
    }
}

@media (max-width: 1200px) {

    .symbols-short-js {
        top: 35%;
        left: 2%;
    } 
}

@media (max-width: 900px) {

    .parallax-container img {
        scale: .8;
    }
}

@media (max-width: 700px) {

    .symbols-short-pen {
        top: 70%;
    }

    .symbols-medium-git {
        top:70%;
    }
}

@media (max-width: 488px) {
    .parallax-container img {
        scale: .6;
    }
}

@media (max-width: 400px) {
    .parallax-container img {
        scale: .5;
    }

    .symbols-short-js {
        left: -10%;
    }
}