@import "./Global.scss"; 


.home-projects-container {
    width: 100%;
    height: 2300px;
    background-color: $color-div-gray;
    overflow: hidden;
}

.projects-main-header {
    @include full-sive-main-header;
}

.cards-flex-wrapper {
    @include flex-container; 
    flex-direction: column;
    gap: 64px;

    margin-top: 24px;

}

// ❗️❗️❗️ responsive ❗️❗️❗️

@media (max-width: 930px) {
    .home-projects-container {
        height: 2200px;
        overflow: hidden;
    }
}

@media (max-width: 800px) {
    .cards-flex-wrapper {
        margin-top: 0px;
    }
}

@media (max-width: 520px) {
    .home-projects-container {
        height: 1900px;
    }
}

@media (max-width: 400px) {
    .cards-flex-wrapper {
        margin-top: -24px;
    }

    .home-projects-container {
        height: 1850px;
    }
}


