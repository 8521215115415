@import "../Styles/_Global.scss";

.projects-card {
    width: 1500px;
    height:600px;
    background-color: white;
    border-radius: 50px;
    box-shadow: $shadow-gentle;

    display: grid;
    grid-template-columns: 61% 39%;

    overflow: hidden;

    &-flex-wrap {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: flex-start;
        flex-direction: column;

        margin-left: 155px;
    }

}

.projects-card, .inner-element {
    overflow: hidden;
}

.proj-meta {
    grid-column-start: 1;
    padding-bottom: 16px;

    font-family: 'Quicksand', sans-serif;
    font-weight: 100;
    font-size: 16px;
    line-height: 100%;

    letter-spacing: 0.16em;
    text-transform: uppercase;

    color: $color-light-grey;
    opacity: .6;
}

.proj-logo {
    grid-column-start: 1;
    padding-bottom: 48px;
}

.proj-text {
    grid-column-start: 1;
    grid-row-start: 3;
    width: 620px;
    height: 160px;
    overflow: hidden;

    @include paragraph-text;

    &-bold-text {
        @include bolt-in-text;
    }

}

.proj-btn {
    @include primary-btn;
    grid-column-start: 1;
}

.proj-img {
    width: 600px;
    grid-column-start: 2;
}

    // ❗️❗️❗️ responsive ❗️❗️❗️ 
    @media (max-width: 1800px) {

        .projects-card {
            width: 1400px;
        }
    }

    @media (max-width: 1600px) {

        .projects-card {
            width: 1300px;
        }

        .projects-card-flex-wrap {
            margin-left: 100px;
        }

    }

    @media (max-width: 1320px) {

        .projects-card {
            width: 1100px;
        }

        .projects-card-flex-wrap {
            margin-left: 80px;
        }

    }

    @media (max-width: 1200px) {

        .projects-card {
            width: 900px;
        }

        .projects-card-flex-wrap {
            margin-left: 80px;
        }

        .proj-logo {
            width: 220px;
        }

        .proj-text {
            font-size: 16px;
            width: 400px;
        }
    }

    @media (max-width: 930px) {
        .projects-card {
            grid-template-columns: 100%;
            grid-template-rows: 45% 55%;
            width: 600px;
        }

        .proj-img {
            grid-column-start: 1;
            grid-row-start: 1;
            align-self: self-end;
            overflow: hidden;

        }

        .projects-card-flex-wrap {
            grid-column-start: 1;
            grid-row-start: 2;
            margin-left: 56px;
        }

        .proj-logo {
            margin-bottom: -20px;
            width: 150px;
        }

        .proj-meta {
            margin-top: 24px;
            font-size: 12px;
        }

        .proj-btn {
            margin-bottom: 40px;
        }
    }

    @media (max-width: 650px) {
        .projects-card {
            grid-template-rows: 40% 60%;
            width: 500px;
        }

        .proj-img {
            width: 500px;
            overflow: hidden;

        }
    }

    @media (max-width: 520px) {
        .projects-card {
            width: 400px;
            height: 500px;
        }

        .projects-card-flex-wrap {
            margin-left: 40px;
        }

        .proj-text {
            width: 320px;
            font-size: 14px;
        }

        .proj-logo {
            margin-bottom: -40px;
            width: 150px;
        }


        .proj-btn {
            width: 200px;
            letter-spacing: 2px;
            font-size: 14px;
            height: 40px;

            & img {
                scale: .8;
            }
        }

        .projects-card {
            border-radius: 24px;
        }

        .proj-img {
            width:400px;
        }
    }


    @media (max-width: 420px) {
        .projects-card {
            grid-template-rows: 40% 60%;
            width: 300px;
        }

        .proj-btn {
            margin-bottom: 40px;
            border-radius: 8px;
            width: 220px
        }

        .proj-btn:hover {
            width: 220px;
            background-color: #2f94ff;
        }

        .proj-btn:focus {
            width: 220px;
            background-color: #2f94ff;
        }

        .proj-img {
            width: 300px;
            overflow: hidden;

        }

        .proj-text {
            width: 240px;
            font-size: 12px;
        }
    }


