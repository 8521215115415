@import "../Styles/_Global.scss";

.intervies-section-container {
    width: 100%;
    height: 1020px;
    overflow: hidden;
}

.canvas-typo-in-page {
    display: none;
}

.interview-main-header {
    @include full-sive-main-header;
}

.intervies-content-grid {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 56px;
}

.motivation-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: fit-content;
    justify-self: center;
}

.frustration-container {
    @extend .motivation-container;
}

.motavationHeader {
    @include section-hedaer;
    margin-bottom: 16px;
}

.frustrationHeader {
    @extend .motavationHeader;
}

.inteview-item {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 16px;
    background-color: $color-div-gray;
    width: 592px;
    height: 72px;
    border-radius: 10000px;

    & p {
        @include paragraph-text;
        line-height: 130%;
        width: 480px;
        font-size: 18px;
        line-height: 140%;;
    }

    & img {
        margin-left: 8px;
    }
}

.motivation-qute-container {
    margin-top: 16px;
    display: flex;
    gap: 16px;
    align-self: center;
    max-width: 600px;
    text-align: center;
}

.frustration-qute-container {
    @extend .motivation-qute-container;
}

.qute-start-sign {
    width: 24px;
    align-self: flex-start;
}

.qute-end-sign {
    @extend .qute-start-sign;
}

.qute-text {
    font-family:  'Klee One', cursive;
    font-weight: 100;
    font-size: 24px;
    line-height: 130%;
    color: $-color-dark-grey;
}

// ❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1400px) {

    .intervies-section-container {
        height: 900px;
    }

    .motavationHeader {
        font-size: 48px;
    }

    .motivation-qute-container {
        max-width: 520px;
    }

    .qute-text {
        font-size: 21px;
    }

    .inteview-item {
        width: 540px;
        height: 60px;

        & p {
            font-size: 16px;
        }

        & img {
            scale: .9;
            margin-left: 4px;
        }
    }

    @media (max-width: 1200px) {
        .intervies-section-container {
            height: 850px;
        }

        .intervies-content-grid {
            margin-top: 40px;
        }
    
        .motavationHeader {
            font-size: 40px;
        }
    
        .motivation-qute-container {
            max-width: 400px;
        }
    
        .qute-text {
            font-size: 16px;
        }
    
        .inteview-item {
            width: 520px;
            height: 60px;
        }
    
    }

    @media (max-width: 1000px) {
        .intervies-section-container {
            height: 1450px;
        }

        .intervies-content-grid {
            grid-template-columns: 100%;
            grid-template-rows: 50% 50%;
            grid-gap: 56px;
            margin-top: 24px;
        }
    }

    @media (max-width: 600px) {
        .inteview-item {
            display: flex;
            background-color: $color-div-gray;
            border-radius: 24px;
            width: 200px;
            height: 180px;
            text-align: center;
        
            & p {
                box-sizing: border-box;
                padding: 24px;
            }
        
            & img {
                display: none;
            }
        }

        .intervies-content-grid {
            grid-template-columns: 50% 50%;
            grid-template-rows: 50% 50%;
            grid-gap: 0px;
            margin-top: 0px;
            margin-left: 24px;
            margin-right: 24px;
        }

        .motivation-container {
            justify-self: center;
            align-items: center;
        }

        .motivation-qute-container {
            display: none;
        }

        .motavationHeader {
            font-size: 32px;
            margin-bottom: 0px;
        }
    }

    .interview-main-header {
        scale: .9;
    }
}


@media (max-width: 500px) {
    .intervies-section-container {
        height: 1100px;
    }
    .interview-main-header {
        scale: .7;
    }

    .inteview-item {
        scale: .9;
    }

    .motavationHeader {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .motivation-container {
        gap: 0px;
    }
}

@media (max-width:400px) {
    .inteview-item {
        scale: .75;
    }

    .intervies-content-grid {
        margin-left: 16px;
        margin-right: 16px;
    }

    .inteview-item {
        margin-top: -24px;
    }

    .motivation-container {
        margin-top: -20px;
    }

    .motavationHeader {
        margin-bottom: 16px;
    }

    .intervies-section-container {
        height: 950px;
    }

}

