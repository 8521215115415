@import "_Global.scss"; 

.dash-typograpy-main-container {
    width: 100%;
    height: 800px;
    overflow: hidden;
    background-color: $color-div-gray;
}

.dash-typography-main-header {
    @include full-sive-main-header;

    @media (max-width: 1800px) {
        width: 1000px;
    }
}

.dash-typography-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: center;
}

.dash-typography-img {
    width: 533px;
    border-radius: 24px;
    box-shadow: $shadow-gentle;
    grid-column-start: 2;
    justify-self: center;
}

.dash-typography-p {
    @include paragraph-text;
    grid-column-start: 1;
    grid-row-start: 1;
    margin-left: 25%;
}

.dash-typography-p b{
   @include bolt-in-text
}

.dash-typography-tilt {
    width: fit-content;
    justify-self: center;
}

//❗️❗️❗️responsive❗️❗️❗️
@media (max-width: 1400px) {
    .dash-typography-img {
        width: 450px;
    }
    .dash-typograpy-main-container {
        height: 750px;
    }
}

@media (max-width: 1200px) {
    .dash-typography-p {
        font-size: 18px;
    }
    .dash-typography-grid {
        margin-top: 32px;
    }
    .dash-typography-img {
        width: 400px;
    }
    .dash-typograpy-main-container {
        height: 700px;
    }
}

@media (max-width: 1000px) {
    .dash-typography-p {
        font-size: 16px;
    }
    .dash-typography-img {
        width: 300px;
    }
    .dash-typograpy-main-container {
        height: 550px;
    }
}

@media (max-width: 800px) {
    .dash-typography-grid {
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
        margin-top: 0px;
        grid-gap: 24px;
    }
    .dash-typography-img {
        grid-row-start: 1;
    }
    .dash-typography-p {
        grid-row-start: 2;
        margin-left: 0;
        width: 600px;
        justify-self: center;
        text-align: center;
    }
    .dash-typograpy-main-container {
        height: 620px;
    }
}

@media (max-width: 600px) {
    .dash-typography-img {
        width: 300px;
    }
    .dash-typography-p {
        width: 70vw;
        font-size: 14px;
    }
    .dash-typograpy-main-container {
        height: 650px;
    }
}