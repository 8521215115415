@import "./Global";

.run-typo-container {
    width: 100%;
    height: 700px;
    background-color: $color-div-gray;
    overflow: hidden;
}

.run-typo-grid {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 60% 40%;
}

.run-typo-p {
    @include paragraph-text;
    width: 700px;
    justify-self: center;
    align-self: center;

    & b {
        @include bolt-in-text;
    }
}

.run-typo-img {
    width: 414px;
    border-radius: 24px;
    box-shadow: $shadow-gentle;
    justify-self: center;
    align-self: center;
}

.run-typo-main-header {
    @include full-sive-main-header;
}

//❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width:1400px) {
    .run-typo-grid {
        margin-right: 100px;
        margin-left: 100px;
    }
}

@media(max-width:1340px) {
    .run-typo-p {
        width: 600px;
    }
    .run-typo-img {
        width: 350px;
    }
    .run-typo-grid {
        margin-top: 40px;
    }
}

@media(max-width:1200px) {
    .run-typo-p {
        width: 450px;
    }
    .run-typo-img {
        width: 350px;
    }
    .run-typo-grid {
        margin-top: 40px;
    }
}


@media (max-width:1000px) {
    .run-typo-img {
        width: 300px;
    }
    .run-typo-p {
        font-size: 18px;
        width: 400px
    }
    .run-typo-container {
        height: 600px;
    }
}

@media(max-width:900px) {
    .run-typo-container {
        height: 750px;
    }
    .run-typo-grid {
        grid-template-rows: 50% 50%;
        grid-template-columns: 100%;
        margin-top: 8px;
    }
    .run-typo-img {
        grid-row-start: 1;
        margin-bottom: 40px;
    }
    .run-typo-p {
        width: 600px;
        text-align: center;
    }
}

@media (max-width:700px) {
    .run-typo-p {
        width: 400px;
    }
    .run-typo-img {
        width: 260px
    }
}

@media (max-width:500px) {
    .run-typo-p {
        width: 300px;
        font-size: 16px;
    }
    .run-typo-img {
        width: 200px
    }
    .run-typo-container {
        height: 600px;
    }
    .run-typo-grid {
        margin-top: -40px;
    }
    .run-typo-main-header {
        scale: .8;
        margin-top: -40px;
    }
}
