@import "../Styles/_Global.scss";


.project-hero-grid-wrapper {
    width: 100%;
    height: 700px;
    background-color: white;
    margin-top: 0;
    position: relative;
    top: -20px; 

    display: grid;
    grid-template-columns: 39% 61%;
    grid-template-rows: 100%;

    overflow: hidden;
}

.project-logo-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 70px;

    & img {
       width: 300px; 
    }

    & h1 {
    font-family: 'Quicksand', sans-serif;
    font-weight: 100;
    font-size: 27px;
    line-height: 100%;
    letter-spacing: 0.03em;
    color: $color-light-grey;
    }
}

.hero-img-project {
    width: 1200px;
    grid-column-start: 2;
    justify-self: start;
    align-self: center;
    overflow: hidden;
}

// ❗️❗️❗️ responsive ❗️❗️❗️ 

@media (max-width: 1600px) {
    .project-logo-flex img {
        width: 250px;
    }

    .project-logo-flex {
        gap: 60px;

        & h1 {
            font-size: 21px;
        }
    }
}

@media (max-width: 1000px) {
    .project-logo-flex img {
        width: 200px;
    }

    .project-logo-flex {
        gap: 40px;
    }
}

@media (max-width: 800px) {
    .project-hero-grid-wrapper {
        grid-template-columns: 50% 50%;
        height: 500px;
    }

    .hero-img-project {
        width: 920px;
    }
}

@media (max-width: 600px) {
    .project-hero-grid-wrapper {
        grid-template-columns: 40% 60%;
        height: 300px;
    }

    .hero-img-project {
        width: 500px;
    }

    .project-logo-flex img {
        width: 100px;
    }

    .project-logo-flex {

        & h1 {
            display: none;
        }
    }
}

@media (max-width: 400px) {
    .project-hero-grid-wrapper {
        grid-template-columns: 50% 50%;
    }
}







