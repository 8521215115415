* {
    margin: 0;
}

.Bg-home-hero {
    width: 100%;
    height: 100vh;
    position: relative;
    background: url(../matirials/Home/hero-img.png) center no-repeat;
    background-size: cover; 
    overflow: hidden;
}