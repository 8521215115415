@import "./Global"; 

.run-flow-container {
    width: 100%; 
    height: 1800px;
    background-color: $color-div-gray;
    overflow: hidden;

}

.run-flow-main-header {
    @include full-sive-main-header;
}

.run-flow {
    position: relative;
    width: 1263px;
    left: 50%;
    top: 50%;
    transform: translateX( -50%);
}

//❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width:1440px) {
    .run-flow-container {
        height: 1500px;
    }
    .run-flow {
        width: 1000px
    }
}

@media (max-width:1200px) {
    .run-flow-container {
        height: 1300px;
    }
    .run-flow {
        width: 900px
    }
}

@media (max-width:900px) {
    .run-flow-container {
        height: 1150px;
    }
    .run-flow {
        width: 800px
    }
}

@media (max-width:800px) {
    .run-flow-container {
        height: 900px;
    }
    .run-flow {
        width: 600px
    }
}

@media (max-width:600px) {
    .run-flow-container {
        height: 650px;
    }
    .run-flow {
        width: 400px
    }
}

@media (max-width:430px) {
    .run-flow-container {
        height: 500px;
    }
    .run-flow {
        margin-top: -20px;
        width: 300px
    }
}


