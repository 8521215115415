@import "../Styles/Global";

ul {
    position: fixed;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-right: 16px;
    transition: all .2s;
    border-radius: 24px;
    width: fit-content;
    overflow: hidden;

    &:hover {
      background-color: rbga(255, 255, 255, .7);
      backdrop-filter: blur(16px);
      box-shadow: $shadow-gentle;
    }
  }
  
  li {
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    gap: 12px;
    margin-bottom: 24px;
    align-items: center;
    cursor: pointer;
  }
  
  .circle {
    width: 12px;
    height: 12px;
    background-color: $-color-dark-grey;
    border-radius: 1000px;
    opacity: .3;
    transition: all .2s ;
  
  }
  
  .a {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Quicksand', sans-serif;
    opacity: 0;
    color: $-color-dark-grey;
    transition: all .2s ;
  }

  li:hover .a {
    opacity: 1;
  }
  
   li:hover .circle {
    opacity: 1;
    transform: scale(1.4);
  } 
  
  .active-circle {
    opacity: 1;
    transform: scale(1.4);
  }

  // ❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 600px) {
  nav {
    display: none;
  }

}