@import "../Styles/_Global.scss";



.vision-main-haeder {
    @include full-sive-main-header;
}

.vision-container {
    width: 100%;
    height: 360px;
    overflow: hidden;
    background-color: $color-div-gray;
    transform-origin: left;
    margin-top: -20px;

    & h1 {    
    font-family: 'Klee One', cursive;
    font-weight: 100;
    font-size: 27px;
    line-height: 130%;
    color: $-color-dark-grey;
    text-align: center;
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 24px;
    }
}

//❗️❗️❗️ responsive ❗️❗️❗️

@media (max-width: 1300px) {
    .vision-container {
        height: 300px;
    }

    .vision-container {
        & h1 {
            font-size: 21px;
            margin-top: 16px;
            margin-left: 150px;
            margin-right: 150px;
        }
    }
}

@media (max-width: 1000px) {

    .vision-container {
        & h1 {
            margin-left: 100px;
            margin-right: 100px;
        }
    }
}

@media (max-width: 700px) {
    .vision-container {
        height: 250px;
    }

    .vision-container {
        & h1 {
            margin-left: 50px;
            margin-right: 50px;
            font-size: 16px;
            margin-top: 0px;
        }
    }
}

@media (max-width: 400px) {
    .vision-container {
        & h1 {
            margin-top: -20px;
            font-size: 12px;
        }
    }
}

