@import "../Styles/_Global.scss";


.next-container {
    width: 100%;
    height: 586px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    &.grey {
        background-color: $color-div-gray;
    }

    &.white {
        background-color:white;
    }
}

.next-card {
    background-color: white;
    width: 1400px;
    height: 348px;
    border-radius: 32px;
    box-shadow: $shadow-gentle;
    display: grid; 
    grid-template-rows: 100%;
    grid-template-columns: 40% 40% 15%;
    overflow:  hidden;
    grid-gap: 40px;

    cursor: pointer;
}

.next-img {
    grid-column-start: 1;
    grid-row-start: 1;
    width: 530px;
}

.next-logo {
    height: 56px;
}

.next-typo {
    grid-column-start: 2;
    grid-row-start: 1;
    width: fit-content;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 24px;

    & h1 {
        font-family: 'Quicksand', sans-serif;
        font-weight: 100;
        color: $-color-dark-grey;
        font-size: 40px;
        text-align: center;
        margin-bottom: 16px;

    }

    & h2 {
        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 56px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #424A52;
        opacity: .4;
    }
}

.next-chevron {
    grid-column-start: 3;
    grid-row-start: 1;
    justify-self: center;
    align-self: center;
    opacity: .4;
    color: $color-light-grey;
}

//❗️❗️❗️ responsive ❗️❗️❗️ 
@media (max-width: 1600px) {
    .next-card {
        width: 1200px;
    }
    .next-typo {
        margin-left: 24px;

        & h2 {
            font-size: 48px;
        }
    }
    .next-img {
        width: 500px;
        align-self:self-end ;
    }
}

@media (max-width: 1400px) {
    .next-card {
        width: 1000px;
    }
    .next-typo {
        margin-left: 24px;

        & h1 {
            font-size: 32px;
        }

        & h2 {
            font-size: 40px;
        }
    }
    .next-img {
        width: 400px;
        margin-left: 24px;
    }
}

@media (max-width: 1200px) {
    .next-container {
        height: 450px;
    }
    .next-card {
        width: 800px;
        height: 250px;
        grid-template-columns: 40% 40% 20%;
    }
    .next-typo {
        margin-left: 24px;

        & h1 {
            font-size: 24px;
        }

        & h2 {
            font-size: 32px;
        }
    }
    .next-img {
        width: 350px;
        margin-left: 24px;
    }

    .next-chevron {
        scale: .6;
        margin-right: 56px;
    }
    .next-logo {
        height: 48px;
    }
}

@media (max-width: 900px) {
    .next-container {
        height: 350px;
    }
    .next-card {
        width: 700px;
        height: 200px;
        grid-template-columns: 40% 40% 20%;
    }
    .next-typo {
        margin-left: 24px;
        gap: 16px;

        & h1 {
            font-size: 16px;
        }

        & h2 {
            font-size: 24px;
        }
    }
    .next-img {
        width: 300px;
        margin-left: 24px;
    }

    .next-chevron {
        margin-right: 80px;
    }
    .next-logo {
        height: 32px;
    }
}

@media (max-width: 700px) {
    .next-chevron {
        display: none;
    }
    .next-card {
        width: 500px;
        grid-template-columns: 40% 60%;
        border-radius: 16px;
    }
    .next-img {
        width: 250px;
        margin-left: -2px;
    }
}

@media (max-width: 500px) {
    .next-container {
        height: 400px;
    }
    .next-card {
        width: 300px;
        height: 300px;
        grid-template-columns: 100%;
        grid-template-rows: 60% 40%;
    }

    .next-typo {
        grid-row-start: 1;
        grid-column-start: 1;
        margin-left: 0;
        justify-self: center;
    }
    .next-img {
        grid-row-start: 2;
        grid-column-start: 1;
        margin-left: 0;
        justify-self: center;
    }
}


