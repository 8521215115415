@import "./_Global.scss"; 

body {
    @include css-reset;
}

.about-container {
    width: 100%;
    height: 800px;
    overflow: hidden;
}

.about-header {
    @include full-sive-main-header;
}

.about-content-flex {
    @include full-size-page-margin;
    @include flex-container;
    gap:16%;

}

.about-text {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 760px; 
    overflow: hidden;

    &-h1 {
        @include section-hedaer;
    }

    &-p {
        @include paragraph-text;
    }
}

.b {
    @include bolt-in-text;
}

.about-img {
    width: 490px;
}

//❗️❗️❗️ responsive ❗️❗️❗️ 

@media (max-width: 1600px) {
    .about-img {
        width: 400px;
    }

    .about-content-flex {
        margin-top: 48px;
    }
}

@media (max-width: 1400px) {
    .about-content-flex {
        gap: 40px;
        margin-left: 140px;
        margin-right: 140px;
    }
}

@media (max-width: 1200px) {
    .about-content-flex {
        gap: 40px;
        margin-left: 100px;
        margin-right: 100px;
    }

    .about-img {
        width: 350px;
    }
}

@media (max-width: 1000px) {

    .about-container {
        height: 700px;
    }
    
    .about-text-h1 {
        font-size: 48px;
    }
    
    .about-text-p {
        font-size: 16px;
    }

    .about-content-flex {
        margin-top: 72px;
    }
}

@media (max-width: 900px) {
    .about-img {
        width: 280px;
    }
}


@media (max-width: 800px) {

    .about-img {
        width: 250px;
    }

    .about-content-flex {
        margin-top:0px;
        flex-direction: column-reverse;
    }

    .about-text-p {
        width: 600px;
        margin-left: 50px;
    }



    .about-text-h1 {
        margin-left: 50px;
    }
}

@media (max-width: 680px) {

    .about-content-flex {
        margin-top: 40px;
        flex-direction: column-reverse;
        gap: 24px;
    }

    .about-text-p {
        width: 520px;
    }

    .about-text {
        width: fit-content;
    }

    .about-text-h1 {
        margin-left: 50px;
    }
}


@media (max-width: 566px) {

    .about-content-flex {
        margin-top: 0;
        gap: 56px;
    }

    .about-img {
        width: 220px;
    }

    .about-text-p {
        width: 420px;
        // margin-left: 150px;
        font-size: 14px;
    }

    .about-text {
        width: fit-content;
    }

    .about-text-h1 {
        // margin-left: 150px;
        font-size: 40px;
    }
}

@media (max-width: 462px) {
    .about-text-p {
        margin-left: 30px;
        width: 370px;
    }

    .about-text-h1 {
        margin-left: 30px;
    }
    
    .about-text {
        width: fit-content;
    }

}

@media (max-width: 390px) {

    .about-content-flex {
        margin-top: 0px;
        gap: 40px;
    }

    .about-text-p {
        margin-left: 16px;
        width: 340px;
    }

    .about-text {
        width: fit-content;
    }


    .about-text-h1 {
        margin-left: 16px;
    }

    .about-container {
        height: 650px;
    }
}




