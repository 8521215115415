@import "../Styles/_Global.scss";

.dash-wire-container {
    width: 100%;
    overflow: hidden;
}
.flex-body {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.gallery {
    width: 100%;
    margin-top: 72px;

    &-container {
        align-items: center;
        display: flex; 
        height: 400px;
        margin: 0 auto;
        max-width: 100px;
        position: relative; 

        //❗️❗️❗️ responsive ❗️❗️❗️
        @media (max-width: 1200px) {
            height: 350px;
        }
    }
}

.item {
    // height: 45vh;
    width: 50vw;
    opacity: 0;
    position:absolute;
    border-radius: 36px;
    transition: all 0.25s ease-in-out;
    z-index: 0;
    background-size: contain;
}

.item1 {
    opacity: 1;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    box-shadow: $shadow-gentle;
}


.item2, .item4 {
    width: 600px;
    opacity: 1;
    z-index: 1;
    filter: blur(2px);
}

.item2 {
    left: -150%;
    transform: translateX(-50%);
}

.item4 {
    left: 250%;
    transform: translateX(-50%);
}

.indicator {
    width: 16px;
    height: 16px;
    border-radius: 1000px;
    background-color: $color-light-grey;
    opacity: .5;
    transition: all 0.3s ease-in-out;
}

.choose {
    background-color: $color-primary-blue;
    opacity: 1;
}

.gallery-indicators {
    width: fit-content;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 800px;
}

.btn {
    border: none;
    background:none;

    &-right {
        margin-right: 32px;
    }

    &-left {
        margin-left: 32px; 
    }

    &:hover {
        cursor: pointer;
    }
}

//❗️❗️❗️ responsive ❗️❗️❗️ 
@media (max-width: 1200px) {
    .dash-wire-container {
        height: 850px;
    }
    .item {
        width: 700px;
        margin-top: -24px;
    }
    .item2, .item4 {
        width: 550px;
    }
    .gallery-indicators {
        margin-top: 700px;
    }
}

@media (max-width: 1000px) {
    .dash-wire-container {
        height: 800px;
    }
    .item {
        width: 600px;
        margin-top: -72px;
        border-radius: 24px;
    }
    .item2, .item4 {
        width: 450px;
    }
    .gallery-indicators {
        margin-top: 600px;
    }
}

@media (max-width: 700px) {
    .dash-wire-container {
        height: 700px;
    }
    .item {
        width: 500px;
        margin-top: -200px;
        border-radius: 24px;
    }
    .item2, .item4 {
        width: 350px;
    }
    .gallery-indicators {
        margin-top: 400px;
    }
}

@media (max-width: 560px) {
    .dash-wire-container {
        height: 550px;
    }
    .item {
        width: 400px;
        margin-top: -300px;
        border-radius: 16px;
    }
    .item2, .item4 {
        width: 250px;
    }
    .gallery-indicators {
        margin-top: 200px;
    }
}


@media (max-width: 480px) {
    .dash-wire-container {
        height: 500px;
    }
    .item {
        width: 350px;
        margin-top: -350px;
        border-radius: 16px;
    }
    .item2, .item4 {
        width: 250px;
    }
    .gallery-indicators {
        margin-top: 100px;
        scale: .9;
        left: 47%;
    }
}


