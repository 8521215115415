@import "../Styles/Global";

.back-home-flex {
    position: fixed;
    display: flex;
    width: fit-content;
    justify-items: center;
    align-items: center;
    gap: 8px;
    top: 24px;
    left: 24px;
    transition: all .2s ;
    z-index: 10;

    & h1 {
        font-family: 'Quicksand', sans-serif;
        font-size: 21px;
        font-weight: 500;
        color: $color-primary-blue;
    }

    & img {
        transform: scale(.6);
    }

    &:hover {
        transform: scale(1.2);
    }

}

.none-display {
    opacity: 0;
}