@import "./Global.scss";

.run-solution-main-container {
    width: 100%;
    height: 800px;
    overflow: hidden;
}

.run-solution-main-header {
    @include full-sive-main-header;
}

.run-solution-contant-grid {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 55% 45%;
    margin-left: 140px;
    margin-right: 140px;
}

.run-solition-cintant-flex {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-self: center;
    justify-self: center;

    & p {
        @include paragraph-text;
        width: 600px;

        & b {
            @include bolt-in-text;
        }
    }
}

.run-solution-notebook {
    width: 1000px;
    transform: scale(.9);
    align-self: center;
    justify-self: center;
}

//❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1600px) {
    .run-solution-main-container {
        height: 700px;
    }

    .run-solution-contant-grid {
        margin-top: -80px;
        margin-left: 100px;
        margin-right: 100px;
    }
    .run-solution-notebook {
        width: 800px;
    }
}

@media (max-width: 1440px) {
    .run-solution-notebook {
        width: 700px;
    }
    .run-solution-contant-grid {
        margin-top: 0px;
    }
}

@media (max-width: 1300px) {
    .run-solition-cintant-flex {
    
        & p {
            font-size: 18px;
            width: 500px;
        }
    }
}

@media (max-width: 1200px) {
    .run-solution-main-container {
        height: 600px;
    }
    .run-solution-contant-grid {
        margin-top: -40px;
    }
    .run-solution-notebook {
        width: 600px;
    }
}

@media (max-width: 1000px) {
    .run-solution-contant-grid {
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
    }
    .run-solition-cintant-flex {
        grid-row-start: 2;
    }
    .run-solution-notebook {
        margin-bottom: -40px;
    }
    .run-solution-main-container {
        height: 800px;
    }
}

@media (max-width: 700px) {
    .run-solution-main-container {
        height: 850px;
    } 
}

@media (max-width: 600px) {
    .run-solution-main-container {
        height: 750px;
    } 
}

@media (max-width:500px) {
    .run-solution-main-container {
        height: 820px;
    } 
    .run-solition-cintant-flex {
        gap: 24px;
        & p {
            font-size: 16px;
            width: 360px;
            text-align: center;
        }
    }
    .run-solution-notebook {
        margin-bottom: -60px;
    }
}

@media (max-width:400px) {
    .run-solution-main-container {
        height: 690px;
    } 
    .run-solution-notebook {
        width: 400px;
        margin-bottom: -30px;
    }
    .run-solition-cintant-flex {
        & p {
            font-size: 14px;
            width: 320px;
        }
    }
}

