@import "../Styles/_Global.scss";

.color-swatch-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    gap: 8px;
}

.color-swatch-squere:hover {
        cursor: pointer;
        width: 140px;
        height: 140px;
        z-index: 2;

        & .color-tool-tip {
            visibility: visible;
            top: -150%;
            opacity: 1;
        }
}


.color-tool-tip {
    width: 400px;
    height: 200px; 
    position: relative;
    background-color: white;
    box-shadow: $shadow-gentle;
    border-radius: 24px;
    visibility: hidden;
    z-index: 2;
    top:0%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    display: grid;
    grid-template-columns: 247.21px 152.79px;
    overflow: hidden;
}



.color-swatch-squere{
    width: 120px;
    height: 120px;
    border: 3px solid rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    transition: all .15s ease-in-out;
}


.color-swatch-name {
    font-family: 'Quicksand', sans-serif;
    font-weight: 300;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: $color-light-grey;
    text-transform: uppercase;
}

//inside tool tip 
.color-tool-tip p {
    @include paragraph-text;
    font-weight: 400;
    color: $-color-dark-grey;
    font-size: 15px;
    place-self: center;
    grid-column-start: 1;
    margin: 24px;

    & b {
        @include bolt-in-text;
    }

}

.color-tooltip-img {
    width:  152.79px;
    height: 200px;
}

div.color-tooltip-img {
    background-color: #FFCC00;
}

//❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1000px) {
    .color-swatch-flex{
        margin-left: -40px;
    }
    .color-swatch-squere{
        width: 90px;
        height: 90px;
        border-radius: 16px;

        &:hover {
            width: 120px;
            height: 120px;

            & .color-tool-tip {
                top: -160%;
                right: 10px;
            }
        }
    }

    .color-tool-tip {
        scale: .8;
    }
    .run-colors-container {
        height: 500px;
    }
}

@media (max-width:900px) {
    .hover-flex-container-in-run-colors {
        display: none;
    }
    .run-colors-container {
        height: 400px;
    }
    .color-swatch-squere{
        &:hover {
            width:72px;
            height: 72px;

            & .color-tool-tip {
                display: none;
            }
        }
    }
}

@media (max-width:600px) {
    .color-swatch-squere{
        width: 70px;
        height: 70px;
        border-radius: 12px;
    }
    .color-swatch-name {
        font-size: 16px;
    }
}
