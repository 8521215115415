@import "./Global";

.error-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    & button {
        @include primary-btn;
        background-color: black;
        box-shadow: none;
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.error-header {
    @include full-sive-main-header;
}

.error-img {
    width: 400px;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.not-found {
    @include section-hedaer;
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translateX(-50%);
}

.p-bottom {
    @include paragraph-text;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}

//❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1000px) {
    .error-img {
        width: 300px;
    }
}

@media (max-width: 850px) {
    .not-found {
        font-size: 40px;
        width: 100%;
        text-align: center;
    }
    .p-bottom {
        font-size: 16px;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 390px) {
    .not-found {
        font-size: 32px;
        width: 100%;
        text-align: center;
    }
    .p-bottom {
        font-size: 14px;
        width: 100%;
        text-align: center;
    }
    .error-img {
        width: 250px;
    }
}



