@import "_Global.scss";

.dash-solution-header {
    @include full-sive-main-header;
}

.dash-solution-container {
    width: 100%;
    height: 700px;
    overflow: hidden;
}

.dash-solution-container-grid {
    margin-top: -30px;
    width: 100%;
    height: 700px;
    background-color: white;

    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;

    overflow: hidden;
    align-items: center;

}

.dash-solution-cont-flex {
    margin-top: -150px;
    margin-left: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 34px;

    //❗️❗️❗️ responsive ❗️❗️❗️
    @media (max-width: 1800px) {
        margin-left: 150px; 
    }

    & p {
        @include paragraph-text;
        width: 664;

    } 
    
}

.dash-solution-img {
    width: 1365.32px;
    grid-column-start: 2;
    justify-self: start;
    align-self: center;
}

.dash-solution-b {
    @include bolt-in-text;
}

// ❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1600px) {
    .dash-solution-container-grid {
        grid-template-columns: 60% 40%;
        margin-top: 24px;
    }

    .dash-solution-cont-flex {
        margin-top: -300px;
    }
}


@media (max-width: 1200px) {
    .dash-solution-cont-flex {
        margin-left: 100px;
        & p {
            font-size: 16px;
        }
    }

    .dash-solution-img {
        width: 1000px;
    }

    .dash-solution-container {
        height: 550px;
    }

    .dash-solution-container-grid {
        margin-top: -40px;
    }
}

@media (max-width: 800px) {

    .dash-solution-img {
        display: none;
    }

    .dash-solution-cont-flex {
        // width: 600px;
        margin-left: 70px;
        margin-right: 70px;
        align-items: center;
        text-align: center;
        width: 80vw;
    }
}

@media (max-width: 600px) {
    .dash-solution-cont-flex {
        width: 500px;
        margin-left: 50px;
        margin-top: -140px;
    }
}


@media (max-width: 500px){
    .dash-solution-cont-flex {
        width: 70vw;
        margin-top: -250px;
        margin-right: 0;
        margin-left: 70px;
    }

    .dash-solution-cont-flex p {
        font-size: 14px;
    }
}

@media (max-width: 400px){

    .dash-solution-container {
        height: 500px;
    }
    .dash-solution-cont-flex {
        width: 80vw;
        margin-left: 40px;
        margin-top: -300px;
    }

    .dash-solution-cont-flex p {
        font-size: 12px;
    }
}








