@import "./Global.scss";

.dash-wire-header {
    @include full-sive-main-header;
}

.dash-wire-container {
    width: 100%;
    height: 1000px;
    background-color: $color-div-gray;
    overflow: hidden;
}

.ui-gallery-container {
    background-color: white; 
    height: 950px;
}

.wireframes-dash-flex-container {
    // margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.hover-flex-container {
    visibility: hidden;
}

.ui-flex-gellary-container {
    margin-top: -32px;
}


