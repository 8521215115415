@import "_Global.scss"; 

.data-architecture {
    @include full-sive-main-header;
    margin-top: 0;
    padding-top: 32px;
}

.Data-container {
    width: 100%;
    height:1000px;
    background: url(../matirials/Dashboard/data-img.png) center  no-repeat;
    background-size:cover;
}


// ❗️❗️❗️ responsive ❗️❗️❗️
@media (max-width: 1550px) {
    .Data-container {
        height:900px;   
    }    
}

@media (max-width: 1400px) {
    .Data-container {
        height:800px;   
        position: relative;
        top: -24px;
    } 
}

@media (max-width: 1250px) {
    .Data-container {
        height:700px;   
    } 
    .data-architecture {
        width: 120%;
    }
}

@media (max-width: 1000px) {
    .Data-container {
        height:600px;   
    } 
    .data-architecture {
        width: 130%;
    }
}


@media (max-width: 850px) {
    .Data-container {
        height:500px;   
    } 
}

@media (max-width: 720px) {
    .Data-container {
        height:400px;   
    } 
    .data-architecture {
        width: 120%;
        padding-top: 0;
    }
}

@media (max-width: 600px) {
    .Data-container {
        height:320px;   
    } 
    .data-architecture {
        width: 180%;
    }
}

@media (max-width: 500px) {
    .Data-container {
        height:280px;   
    } 
    .data-architecture {
        width: 200%;
    }
}

@media (max-width: 420px) {
    .Data-container {
        height:240px;   
    } 
    .data-architecture {
        width: 180%;
    }
}


