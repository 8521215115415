@import "./Global";

.aime-nav-container {
    width: 100%; 
    height: 500px;
    overflow: hidden;

    p {
        @include  paragraph-text;
        position: relative;
        top: -380px;
        width: 850px;
        margin-left: 200px
    }
}

.aime-nav-header {
    @include full-sive-main-header;
}

.aime-nav-Img {
    width: 600px;
    position: relative;
    left: 60%;
    top: -230px;
}

//❗️❗️❗️responsive❗️❗️❗️
@media (max-width: 1700px) {
    .aime-nav-container {
        p {
            width: 850px;
            margin-left: 150px
        }
    }
}

@media (max-width: 1600px) {
    .aime-nav-container {
        p {
            width: 750px;
            margin-left: 120px
        }
    }
}

@media (max-width: 1450px) {
    .aime-nav-container {
        p {
            width: 700px;
            margin-left: 120px;
            top: -320px;
        }
    }
    .aime-nav-Img {
        width: 500px;
    }
}

@media (max-width: 1300px) {
    .aime-nav-container {
        p {
            width: 600px;
        }
    }
}

@media (max-width: 1200px) {
    .aime-nav-container {
        p {
            width: 500px;
            margin-left: 120px;
            top: -320px;
        }
    }
    .aime-nav-Img {
        width: 500px;
    }
}

@media (max-width: 1000px) {
    .aime-nav-container {
        p {
            margin-left: 120px;
            top: -450px;
            font-size: 18px;
        }
    }
    .aime-nav-Img {
        width: 700px;
        left:65%;
    }
}

@media (max-width: 900px) {
    .aime-nav-container {
        p {
            margin-left: 100px;
            top: -400px;
            font-size: 18px;
        }
    }
    .aime-nav-Img {
        width: 600px;
        left:75%;
    }
}

@media (max-width: 720px) {
    .aime-nav-Img {
        opacity: 0;
    }
    .aime-nav-container {
        p {
            opacity: 1;
            text-align: center;
            margin-left: 0;
            width: 70vw;
            left:50%;
            transform: translateX(-50%);
        }
    }
    .aime-nav-container {
        height: 450px;
    }
}

@media (max-width: 600px) {
    .aime-nav-container {
        p {
            margin-top: -20px;
            font-size: 16px;
        } 
    }
}

@media (max-width: 400px) {
    .aime-nav-container {
        p {
            margin-top: -40px;
            font-size: 12px;
        } 
    }
    .aime-nav-container {
        height: 350px;
    }
}





